/* MB Typography */
// Material Icons

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: local('Material Icons'),
		 local('MaterialIcons-Regular'),
		 url(../material-icons/fonts/MaterialIcons-Regular.woff2) format('woff2'),
		 url(../material-icons/fonts/MaterialIcons-Regular.woff) format('woff'),
		 url(../material-icons/fonts/MaterialIcons-Regular.ttf) format('truetype');		
}

@font-face {
	font-family: "Material Symbols";
	font-size: normal;
	font-weight: 400;
	src : url(../material-icons/fonts/MaterialIcon-Symbols-Outlined.woff2) format('woff2'),
		url(../material-icons/fonts/MaterialIcon-Symbols-Outlined.ttf) format('truetype');
}

.material-icons {
	font-family: 'Material Icons' !important;
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	vertical-align: bottom;
  
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
  
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
  
	/* Support for IE. */
	font-feature-settings: 'liga';

	&.icon-large {
		font-size : 50px;
	}
}

.material-symbols-outlined {
	font-family: 'Material Symbols' !important;
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	vertical-align: bottom;
  
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
  
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
  
	/* Support for IE. */
	font-feature-settings: 'liga';

	&.icon-large {
		font-size : 50px;
	}
}