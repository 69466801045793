/* MB Variable Overrides */

// Color System

// Brand Colors
$mb-gold-1:         #FFEA00;
$mb-gold-2:         #FFD200;
$mb-gold-3:         #FBBF0E;
$mb-gold-4:         #CCBB00;
$black:             #000000;
$white:             #FFFFFF;

// Charcoal
$charcoal-50:       #F7FBFE;

$charcoal-100:      #F2F6F9;

$charcoal-200:      #ECF0F3;
$charcoal-300:      #CACFD2;
$charcoal-400:      #BCC0C2;
$charcoal-500:      #9DA1A3;
$charcoal-600:      #74777A;
$charcoal-700:      #606366;
$charcoal-750:      #414446;
$charcoal-800:      #2F3335;
$charcoal-900:      #202325;
$charcoal-925:      #1C1E1F;
$charcoal-950:      #191A1C;
$charcoal-975:      #161719;  // default ink
$charcoal-dark:     #080808;

$body-color:        $charcoal-900 !default;


//Surface
$surface-light:     $white;
$surface-light-grey: #F2F2F2;
$surface-dark:      #F3F4F5;
$surface-darker:    #E6E6E6;
$surface-base:      #FAFAFA;
$light-gray:        #DFDFDF;
$gray:              #CCCCCC;

// Primary & Secondary Colors
$blue:                  #0d8aee;
$blue-light-1:          lighten($blue, 6%);
$blue-light-2:          lighten($blue, 24%);
$blue-light-3:          lighten($blue, 36%);
$blue-light-4:          lighten($blue, 48%);
$blue-dark-1:           darken($blue, 6%);
$blue-dark-2:           darken($blue, 12%);
$blue-dark-3:           darken($blue, 18%);
$blue-dark-4:           darken($blue, 24%);

$green:                 #4CAF50;
$green-light-1:         lighten($green, 6%);
$green-light-2:         lighten($green, 24%);
$green-light-3:         lighten($green, 36%);
$green-light-4:         lighten($green, 44%);
$green-light-5:         lighten($green, 48%);
$green-dark-1:          darken($green, 6%);
$green-dark-2:          darken($green, 12%);
$green-dark-3:          darken($green, 18%);
$green-dark-4:          darken($green, 24%);
$green-highlight:       #33ff33;

$yellow:                $mb-gold-2;
$yellow-light-1:        lighten($yellow, 6%);
$yellow-light-2:        lighten($yellow, 24%);
$yellow-light-3:        lighten($yellow, 36%);
$yellow-light-4:        lighten($yellow, 48%);
$yellow-dark-1:         darken($yellow, 6%);
$yellow-dark-2:         darken($yellow, 12%);
$yellow-dark-3:         darken($yellow, 18%);
$yellow-dark-4:         darken($yellow, 24%);

$orange:                #F57208;
$orange-light-1:        lighten($orange, 6%);
$orange-light-2:        lighten($orange, 24%);
$orange-light-3:        lighten($orange, 36%);
$orange-light-4:        lighten($orange, 48%);
$orange-dark-1:         darken($orange, 6%);
$orange-dark-2:         darken($orange, 12%);
$orange-dark-3:         darken($orange, 18%);
$orange-dark-4:         darken($orange, 24%);

$red:                   #D32F2F;
$red-light-1:           lighten($red, 6%);
$red-light-2:           lighten($red, 24%);
$red-light-3:           lighten($red, 36%);
$red-light-4:           lighten($red, 48%);
$red-dark-1:            darken($red, 6%);
$red-dark-2:            darken($red, 12%);
$red-dark-3:            darken($red, 18%);
$red-dark-4:            darken($red, 24%);

$purple:                #8a55a1;
$purple-light-1:        lighten($purple, 6%);
$purple-light-2:        lighten($purple, 24%);
$purple-light-3:        lighten($purple, 36%);
$purple-light-4:        lighten($purple, 48%);
$purple-dark-1:         darken($purple, 6%);
$purple-dark-2:         darken($purple, 12%);
$purple-dark-3:         darken($purple, 18%);
$purple-dark-4:         darken($purple, 24%);

// Tertiary Colors

$cyan:              #00bcd4;

$teal-lighter:      #B3E4E4;
$teal-light:        #80D3D2;
$teal:              #47C1BF;
$teal-dark:         #00A49E;
$teal-darker:       #00766E;

$magenta-lighter:   #E5B8D6;
$magenta-light:     #D489BB;
$magenta:           #C159A1;
$magenta-dark:      #B2328F;
$magenta-darker:    #880074;

$indigo-lighter:    #C5CAEA;
$indigo-light:      #9FA8DC;
$indigo:            #5C6AC4;
$indigo-dark:       #3F50B9;
$indigo-darker:     #283397;

$mb-primary-colors: (
    "blue": $blue,
    "green": $green,
    "yellow": $mb-gold-2,
    "orange": $orange,
    "red": $red,
    "purple": $purple,
);

$mb-tertiary-colors: (
    "teal": $teal,
    "magenta": $magenta,
    "indigo": $indigo
);

$mb-charcoal-colors: (
    "charcoal-50":       $charcoal-50,
    "charcoal-100":      $charcoal-100,
    "charcoal-200":      $charcoal-200,
    "charcoal-300":      $charcoal-300,
    "charcoal-400":      $charcoal-400,
    "charcoal-500":      $charcoal-500,
    "charcoal-600":      $charcoal-600,
    "charcoal-700":      $charcoal-700,
    "charcoal-750":      $charcoal-750,
    "charcoal-800":      $charcoal-800,
    "charcoal-900":      $charcoal-900,
    "charcoal-925":      $charcoal-925,
    "charcoal-950":      $charcoal-950,
    "charcoal-975":      $charcoal-975
);

$mb-surface-colors: (
    "surface-light":    $surface-light,
    "surface-dark":     $surface-dark,
    "surface-darker":   $surface-darker,
    "surface-base":     $surface-base,
    "light-gray":       $light-gray,
    "gray":             $gray
);

// Color Overrides

$primary-color: $blue;
$secondary-color: $light-gray;
$info-color: $teal;
$success-color: $green;
$danger-color:  $red;
$default-color: $light-gray;



// Button Overrides
$btn-padding-y: .5rem;
$btn-padding-x: 1.1rem;

$btn-padding-y-sm: .35rem;
$btn-padding-x-sm: .7rem;

$btn-padding-y-lg: .69rem;
$btn-padding-x-lg: 3rem;

// Grid breakpoints
//
$grid-breakpoints:(
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

$container-max-widths:(
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1520px
);

// For Bootstrap 5 Components 
$zindex-offcanvas-backdrop: 70101;
$zindex-offcanvas: 75000;
$modalContainerZIndex: 82100;
$modalBackdropZIndex: 70101;
$mbModalContainerZIndex: 84000;
$headerZIndex: 70100;

// Border Override 
$border-color: $charcoal-300;
