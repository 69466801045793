@import "mb-globals/variables";
@import "mb-globals/mixins";
@import "mb-globals/typography";


.print-on { display: none; }
.print-off { display: inherit; }

.printElement .print-on { display: inherit; }
.printElement .print-off { display: none; }

/* Print 
---------------------------------------------------------------------------------------------------*/

@media print {
    @page { 
        size: auto; /* auto is the initial value */ 
        margin: 4mm; /* this affects the margin in the printer settings */
    } 

	body {
		font: 0.875em/1.5em Arial, Helvetica, sans-serif;
		color: $body-color;
    }

    header {
        display: none;
    }

    .wrapper {
        padding-top: 0 !important;
    }

    .mb-overlay.open {
        position: absolute !important;
        display: block !important;
        width: auto !important;
        height: auto !important;
        overflow: visible !important;
        top: 0 !important;
        left: 0 !important;
    }

    button.close-button {
        display: none;
    }

    .d-none:not(.ingredient-tab-content) {
        display: none; 
    }
    
	a, a:link, a:hover {
		text-decoration: none;
    }
    
	p {
		margin: 0;
    }

    .right {
        float: right;
    }

    .left {
        float: left;
    }
    
	h1, h2, h3 {
		margin: 0 0 1.3em 0;
    }
    
	h4 {
		font-size: 1.1em;
		margin: 0 0 7px 0;
    }
    
	h2 {
		font-family: "Oswald", Helvetica, Arial, sans-serif;
		font-size: 1.7em;
		line-height: 1.3em;
		font-weight: 400;
		text-transform: uppercase;
		margin: 0 0 10px 0;
    }
    
	table { 
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
        margin-bottom: 20px;

        td {
            border: 1px solid $black;
            @include font-size(12px);
            padding: 4px;
        }
    }


    .table-secondary th {
        text-align: left;
    }

    .table-secondary th,
    .table-secondary td {
        color: $white;
        font-weight: 700 !important;
        background-color: $charcoal-600;
        @include font-size(12px);
        border-color: #dee2e6 !important;
        padding: 8px 10px !important;
    }

	.print-page { 
        page-break-after: always;
		min-height: 400px; 
		position: relative; 
        padding-bottom: 40px; 
        
        &:after {
            content: "page break"; 
            color: $gray; 
            text-decoration: underline; 
            display: block; 
            position: absolute; 
            bottom: 10px;  
        }

        &:last-child {
            page-break-after: auto;
        }
    }
    
    .recipe {
        @include font-size(12px); 

        .formcontents {
            font-weight: bold;
        }

        h3 {
            margin: 0;
        }

        .formlabel {
            clear:both;
            font-weight: normal;
        }

        table {
            border:solid $black !important;
            border-width:1px 1px 0 1px !important;
        }

        thead tr, 
        tfoot tr {
            background-color: $gray;
            color: $black;
            -webkit-print-color-adjust: exact;
        }

        th, td {
            border:solid $black !important;
            border-width:0 0 1px 0 !important;
            @include font-size(12px);
        }
    }


    .recipe-nutrient {
        margin-right: 0.5rem;
        margin-bottom: 0.25rem;
    }

    img[alt="Loading"] {
        display: none;
    }

    .ingredient-page {

        .h-115px {
            max-height: 115px;
        }
        
        input { display: none; }
    
        .empty-row,
        .material-icons,
        button,
        .error-message,
        .ingredient-nav {
            display: none !important;
        }
    
        h2.print-header {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    
        #ingredientsRecipesContainer ul .nutrition-row div {
            display: inline-block;
        }
    
        #ingredientsRecipesContainer ul .nutrition-row .customer-name {
            margin-left: 5px;
        }
    }


	.threecol {
		float: left;
		width: 33%;
		padding-right: 10px;
	}	
	
	.print-no-paging {
		page-break-after: avoid;
    }

    div.no-page-break div {
		page-break-inside: initial;
    }
    
	.jrPage {page-break-after:always;}
	
	.print-on { display: inherit; }
    .print-off { display: none; }
    

    .print-page {page-break-after: always;}

    #pageHeader {
    	display: none;
    	height: 0;
    }

    #pageFooter {
    	display: none;
    	height: 0;
    }

	.fullwidth {
		padding: 40px 2% 70px;
	}
	.p-t-70 {
		padding: 10px !important;
	}
	.hide_on_print {
		display: none !important;
	}
	footer {
		display: none;
	}
	.zebra div:nth-child(even), .zebra tr:nth-child(even) {
		background-color: #EEE;
		-webkit-print-color-adjust: exact;
	}
	.zebra div:nth-child(odd), .zebra tr:nth-child(odd) {
		background-color: #FFF;
		-webkit-print-color-adjust: exact;
	}
	.zebra div:hover, .zebra tr:hover {
		color: #000;
    }

    table.subgrid, 
    table.grid, 
    table.baregrid {
        thead tr, 
        tfoot tr {
            padding: 0.2em;
            text-align: left;
            background-color: $charcoal-600;
            background-repeat: repeat-x;
            color: #fff;
            font-weight: bold;
            font-style: normal;
            border-collapse: collapse;
            border-right-style: solid;
            border-left-style: solid;
            border-bottom-style: solid;
            border-top-style: solid;
            border-right-width: 1px;
            border-left-width: 1px;
            border-bottom-width: 1px;
            border-top-width: 1px;
            border-right-color: $charcoal-600;
            border-left-color: $charcoal-600;
            border-bottom-color: $charcoal-600;
            border-top-color: $charcoal-600;
        }
    }

}